
import { defineComponent, ref, watch, computed } from 'vue';
import { useOrgManager } from '@/composables/useOrgManager';

export default defineComponent({
  name: 'SingleOrgPicker',
  props: {
    modelValue: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    console.log(props.modelValue);

    const { orgs, orgMap } = useOrgManager();

    const selectVal = ref(props.modelValue);

    watch(selectVal, val => {
      context.emit('update:modelValue', val);
    });

    watch(
      computed(() => props.modelValue),
      val => (selectVal.value = val),
    );

    return { orgs, selectVal };
  },
});
