
import { defineComponent, computed, ref } from 'vue';
import { useWorkflowDetail } from '@/composables/useWorkflowDetail';
import { FetchStates } from '@/helpers/fetch-states';
import router from '@/router';
import { useAuth } from '@/composables/useAuth';
import SingleOrgPicker from '@/components/orgs/SingleOrgPicker.vue';

export default defineComponent({
  name: 'EditWorkflowPage',
  components: { SingleOrgPicker },
  setup() {
    const workflowId = computed(
      () => router.currentRoute.value.params['id'] as string,
    );

    const { isVAR, isSuperAdmin, account } = useAuth();

    const {
      workflow,
      fetchState,
      editableWorkflow,
      isEditing,
      createWorkflow,
      updateWorkflow,
    } = useWorkflowDetail(workflowId);

    if (!isEditing.value)
      editableWorkflow.value.org = account.value?.organizations_id ?? 0;

    console.log(editableWorkflow.value);

    const routeToWorkflowsList = () => router.push({ name: 'WorkflowsPage' });

    const routeBack = () => {
      if (window.history.state.back === null) {
        routeToWorkflowsList();
      } else {
        router.back();
      }
    };

    return {
      workflowId,
      isEditing,
      workflow,
      editableWorkflow,
      fetchState,
      createWorkflow,
      updateWorkflow,
      isVAR,
      isSuperAdmin,
      routeToWorkflowsList,
      routeBack,
      FetchStates,
    };
  },
});
