import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")

  return (_openBlock(), _createBlock(_component_el_select, {
    filterable: "",
    modelValue: _ctx.selectVal,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectVal = $event)),
    placeholder: "Select Organization"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.orgs, (org) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: org.id,
          label: org.name,
          value: org.id
        }, null, 8, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}