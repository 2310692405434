import {
  CreateWorkflowPayload,
  UpdateWorkflowPayload,
  WorkflowDTO,
} from '@/api/dto/workflow.dto';
import {
  createSingleWorkflow,
  fetchSingleWorkflow,
  updateSingleWorkflow,
} from '@/api/report-builder';
import { alertError } from '@/helpers/alert-error';
import { FetchStates } from '@/helpers/fetch-states';
import router from '@/router';
import { ElMessage } from 'element-plus';
import { computed, Ref, ref, shallowRef, watch } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

export function useWorkflowDetail(id: Ref<string>) {
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const workflow = shallowRef<WorkflowDTO | null>(null);
  const editableWorkflow = ref({
    title: '',
    code_block: '',
    event: '',
    org: 0,
  });

  const isEditing = computed(() => id.value !== 'create');

  const fetchWorkflow = async () => {
    if (!id.value) return;

    fetchState.value = FetchStates.FETCHING;

    try {
      const fetchedWorkflow = await fetchSingleWorkflow(id.value);
      workflow.value = fetchedWorkflow;

      editableWorkflow.value = {
        title: fetchedWorkflow.title,
        code_block: JSON.stringify(fetchedWorkflow.code_block),
        event: fetchedWorkflow.event,
        org: fetchedWorkflow.org!,
      };

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);
      alertError(error, 'There was an error fetching the workflow.');
      router.replace({ name: 'WorkflowsPage' });
    }
  };

  const updateWorkflow = async () => {
    if (!id.value) return;

    try {
      const updatedWorkflow: UpdateWorkflowPayload = {
        _id: id.value,
        title: editableWorkflow.value.title,
        event: editableWorkflow.value.event,
        org: editableWorkflow.value.org,
        code_block: JSON.parse(editableWorkflow.value.code_block),
      };

      await updateSingleWorkflow(updatedWorkflow);

      fetchState.value = FetchStates.FETCHED;

      ElMessage.success('Successfully updated workflow');
    } catch (error) {
      fetchState.value = FetchStates.FETCHED;
      console.error(error);
      alertError(error, 'There was an error updating the workflow.');
    }
  };

  const createWorkflow = async () => {
    fetchState.value = FetchStates.FETCHING;

    try {
      const newWorkflow: CreateWorkflowPayload = {
        title: editableWorkflow.value.title,
        event: editableWorkflow.value.event,
        org: editableWorkflow.value.org,
        code_block: JSON.parse(editableWorkflow.value.code_block),
      };

      await createSingleWorkflow(newWorkflow);

      fetchState.value = FetchStates.FETCHED;

      ElMessage.success('Successfully created workflow');

      router.push({ name: 'WorkflowsPage' });
    } catch (error) {
      fetchState.value = FetchStates.UNFETCHED;
      console.error(error);
      alertError(error, 'There was an error creating the workflow.');
    }
  };

  const stopWatchingId = watch(id, fetchWorkflow);
  onBeforeRouteLeave(stopWatchingId);

  if (isEditing.value) fetchWorkflow();

  return {
    fetchState,
    workflow,
    editableWorkflow,
    fetchWorkflow,
    updateWorkflow,
    createWorkflow,
    isEditing,
  };
}
